import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';

const LANGUAGE_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageLocalizationService {

  selectedLanguage: string = 'nl'; //ISO code
  private languageOptions = [
    { name: 'English', value: 'en' },
    { name: 'Dutch', value: 'nl'}
  ]

  constructor(private translate: TranslateService, private storage: Storage, private platform: Platform) {
    this.setInitialLanguage();
  }

  setInitialLanguage() {
    let browserLanguage = this.translate.getBrowserLang(); //Default language requested from browser.
    this.translate.setDefaultLang(browserLanguage);
    this.storage.create();

    //Check if user has seleced a preferred language and apply it.
    this.storage.get(LANGUAGE_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
        this.selectedLanguage = val;
      }
      else{
        this.selectedLanguage = browserLanguage;
      }
    });
  }

  setLanguage(lng) {
    this.translate.use(lng); //ngx-translate uses the json files in the i18n folder to translate the app.
    this.selectedLanguage = lng;
    this.storage.set(LANGUAGE_KEY, lng);
  }

  getLanguageOptions(){
    return [...this.languageOptions];
  }
}
