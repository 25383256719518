export class NewLocationGreenhouse {
    loc_name: string
    coordination_type: string
    paths: number
    cols: number
    width: number
    height: number
}

export class NewFlyMeasurement {
    species: string
    count: number
    y: number
    x: number
}

export class NewLocationMeasurementSettings {
    species: string
    threshold: number
    threshold_caution: number
}

export class EditableLocationMeasurementSetting {
    measurementSetting: NewLocationMeasurementSettings
    enabled: boolean
}

export class DateRange {
    start: Date
    end: Date
}

export class NewUserRole {
    email: string
    role: string
}

export class Organization {
    organization_id: string
    name: string
    slug: string
}

export class Module {
    name: string
    title: string
    img_src: string
    description: string
    button_link: string
    features: string[]
    expanded: boolean
    selected: boolean
}

export class NewScoutcam {
    serial_code: string
    scout_loc_id: number
    x: number
    y: number
}

export class Scout {
    scout_id: string
    serial_code: string
    x: number
    y: number
    scout_loc_id: string
}

export class NewScoutcamFlex {
    serial_code: string
    scout_loc_id: number
}

export class ScoutFlex {
    scout_id: string
    serial_code: string
    scout_loc_id: string
}

export class ScoutFlexAcquisition {
    scout_id: string
    serial_code: string
    scout_loc_id: string
    acquired_image_count: number
}

export class EditScoutcam {
    scout_loc_id: number
    x: number
    y: number
}

export class DataEntry {
easy_id: string
class_int: number
class_name: string
x_center: number
y_center: number
width: number
height: number
confidence_score: number
results_txt_path: string
batch_upload_time: string
battery_charge_level: number
battery_temperature: number
battery_supply_voltage: number
battery_supply_current: number
device_io_voltage: number
device_io_current: number
}

export class Bluetooth {
    serial_code: string;
    device_id: string;
    service_uuid: string;
    characteristic_uuid: string;
}

export class ScoutcamMessage {
    message: string;
    message_by: string;
}

export class Experiment {
    environment_id: string;
    name: string;
    owner_id: string;
    start_date: Date;
    end_date?: Date;
    run_times: number[];
    table: Table;
}

export class TableRow {
    sid: number;
    device_id: string;
    plot_number: number;
    empty?: boolean;
    entries: TableEntry[]
}

export class Table {
    rows: TableRow[]
}

export class TableEntry {
    key: string;
    value: string;
    dtype: string;
    accepted: boolean = true;
    error: string = null;
    options: string[] = null;
}

export class Greenhouse {
    location_id: string;
    loc_name: string;
    coordination_type: string;
    paths: number;
    cols: number;
    width: number;
    height: number;
    settings: any[];
    modules: string[];
}

export class Stripholder {
    card_holder_id: string;
    location_id: string;
    strip_number: number;
    x: number;
    y: number;
}

export class StripholderIn {
    location_id: string;
    strip_number: number;
    x: number;
    y: number;
}

export class User {
    user_id: string;
    email: string;
    full_name: string;
    user_org_id: string;
    user_organization_name: string;
    user_organization_slug: string;
}
