// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBAC1oIbibrBqGfJm8G8F9I2udgNdGk6pg",
    authDomain: "sobolt-plantfellow.firebaseapp.com",
    databaseURL: "https://sobolt-plantfellow.firebaseio.com",
    projectId: "sobolt-plantfellow",
    storageBucket: "sobolt-plantfellow.appspot.com",
    messagingSenderId: "1057238228459",
    appId: "1:1057238228459:web:3ce17caae76622c9f3cdae"
  },
  APP_API_URL: "https://test.unified.plantfellow.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
