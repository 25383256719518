import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRequestsService } from '../http/api-requests.service';
import { AsyncSubject, BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from '../interface/interfaces';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  constructor(
    public http: HttpClient,
    public apirequest: ApiRequestsService
  ) { }

  /**
   * Fetch details of the currently signed-in user.
   * @returns {Observable<User>} the currently signed-in user object
   */
  getCurrentUser(): Observable<User> {
    return this._currentUser$.asObservable();
  }

  /**
   * (re)load the currently signed-in user.
   * NOTE: Required when the current user signs in/out.
   */
  loadCurrentUser(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let endpoint = `/api/current_user`;
      this.http.get<User>(endpoint).pipe(first()).subscribe({
        next: (user: User) => {
          this._currentUser$.next(user);
          resolve(true);
        },
        error: (err) => {
          console.debug(err);
          reject(false);
          // TODO: Implement error handling...
        }
      })

    })
  }

  IDtoName(id) {
    return new Promise<any>((resolve, reject) => {
      this.apirequest.sendGetRequest('/api/users/' + id)
        .subscribe((user: any) => {
          resolve(user.full_name);
        })
      })
  }
}
