import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DateRange, Table } from '../interface/interfaces';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestsService {

  public httpHeaders: any;
  public basic_link: string;

  constructor(
    private http: HttpClient,
    public platform: Platform
  ) {
    if (this.platform.is('capacitor')) {
      this.basic_link = environment.APP_API_URL;
    } else {
      this.basic_link = "";
    }

    this.initializeHttpHeader();
  }

  /**
   * Initialize the http header used for all requests to the backend.
   */
  public initializeHttpHeader() {
    this.httpHeaders = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Methods, Access-Control-Allow-Credentials, Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'Access-Control-Allow-Credentials': 'true'
      })
    };
  }

  /**
   * Set authorization of the http header.
   * @param {string} idToken The OAuth id token of the user.
   */
  public setAuthorizationHeader(idToken: string) {
    this.httpHeaders = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Methods, Access-Control-Allow-Credentials, Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization': `Bearer ${idToken}`
      })
    };
  }

  /**
   * Send a GET request to an url.
   * @param {string} url URL/enpoint to send the request to
   * @returns {Observable<ArrayBuffer>} An Observable of the response, with the response
   *  body as an ArrayBuffer.
   */
  public sendGetRequest(url: string) {
    url = this.basic_link + url;
    delete this.httpHeaders.responseType; // Explicitly delete httpHeader property.
  	return this.http.get(url, this.httpHeaders);
  }

  /**
   * Send a GET request to an url expecting a json response.
   * @param {string} url URL/enpoint to send the request to
   * @returns {Observable<ArrayBuffer>} An Observable of the response, with the response
   *  body as an ArrayBuffer.
   */
  public sendGetRequestJSON(url: string) {
    url = this.basic_link + url;
    this.httpHeaders.responseType = 'json';
    return this.http.get(url, this.httpHeaders);
  }

  /**
   * Send a GET request to an url expecting a blob response.
   * @param {string} url URL/enpoint to send the request to
   * @returns {Observable<ArrayBuffer>} An Observable of the response, with the response
   *  body as an ArrayBuffer.
   */
  public sendGetRequestBlob(url: string) {
    url = this.basic_link + url;
    this.httpHeaders.responseType = 'blob';
    return this.http.get(url, this.httpHeaders);
  }

  public sendGetRequestDateRange(url: string, daterange: DateRange) {
    url = this.basic_link + url;
    return this.http.post(url, daterange, this.httpHeaders);
  }

  /**
   * Send a POST request to an url with application/json.
   * @param {string} url URL/enpoint to send the request to
   * @param {any} data payload of the request (content-type: application/json)
   * @returns {Observable<ArrayBuffer>} An Observable of the response, with the response
   *  body as an ArrayBuffer.
   */
  public sendPostRequest(url: string, data: any) {
    url = this.basic_link + url;
    delete this.httpHeaders.responseType; // Explicitly delete httpHeader property.
    return this.http.post(url, data, this.httpHeaders);
  }

  sendPostRequestFormData(endpoint, file) {
  	const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(endpoint, formData, this.httpHeaders);
  }

  sendPostRequestTable(endpoint, file) {
  	return this.http.post<Table>(endpoint, file, this.httpHeaders);
  }


  public sendPutRequest(url: string, data: any) {
    url = this.basic_link + url;
    delete this.httpHeaders.responseType; // Explicitly delete httpHeader property.
    return this.http.put(url, data, this.httpHeaders)
  }

  public sendDeleteRequest(url: string) {
    url = this.basic_link + url;
    delete this.httpHeaders.responseType; // Explicitly delete httpHeader property.
    return this.http.delete(url, this.httpHeaders);
  }
}
