import { Component, OnInit } from '@angular/core';
import { LanguageLocalizationService } from './core/localization/data-access/language-localization.service';
import {
	applyPolyfills,
	defineCustomElements,
} from "@sobolt/sobolt-components/loader";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(private languageLocalization: LanguageLocalizationService) {}

  ngOnInit(): void {
  }
}

// =
