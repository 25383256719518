import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { AuthService } from '../auth/auth.service';
import { Platform } from '@ionic/angular';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpAuthorizationInterceptor implements HttpInterceptor {
    public basic_link: string;
    constructor(
        private authService: AuthService,
        public platform: Platform
        ) {
            if (this.platform.is('capacitor')) {
                this.basic_link = environment.APP_API_URL;
            } else {
                this.basic_link = "";
            }
         }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with token if available
        if (this.authService.current_user_token) {
            request = request.clone({
                url: this.basic_link + request.url,
                setHeaders: {
                    Authorization: `Bearer ${this.authService.current_user_token}`
                }
            });
        }

        return next.handle(request).pipe(tap(res => console.debug(`${request.method} REQUEST TO ${request.url}`)));
    }
}
