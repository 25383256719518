import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithRedirect,
  GoogleAuthProvider,
  OAuthProvider,
  getRedirectResult,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  initializeAuth,
  indexedDBLocalPersistence
} from "firebase/auth";
import { app } from "../../app.module"
import { initializeApp } from "firebase/app"
import { ApiRequestsService } from '../http/api-requests.service';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../interface/interfaces';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loading: boolean = false;
  public user_id: string;
  public current_user: User;
  public userinfo: any;
  public current_user_token: string;

  private app: any;

  constructor(
    private apiRequestsService: ApiRequestsService,
    public router: Router,
    public userService: UserService
  ) {
  }

  /**
   * Creates and returns a Firebase authentication object
   * @returns {object} Firebase authentication object
   */
  public getAuth() {

    let auth;
    if(Capacitor.isNativePlatform()){
      auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence
      });
    } else{
      auth = getAuth();
    }


    // Option to explicitly set the language:
    // auth.languageCode = 'nl';
    // auth.languageCode = 'en';

    return auth;
  }

  /**
   * Add the OAuth token to the authorization header in the http api-requests service.
   * @param {any} callback_success Callback function on successfully setting the http authorization header
   * @param {any} callback_error Callback function on error when setting the http authorization header
   */
  public setHttpHeaderToken(callback_success: any, callback_error: any) {
    const auth = this.getAuth();
    auth.currentUser.getIdToken(true)
      .then((token: string) => {
        this.current_user_token = token;
        this.userService.loadCurrentUser().then((user_loaded: boolean) => {
          // this.permissionServices.loadMyRolesAndPermissions().then((loaded: boolean) => {
          //   resolve(true);
          // })
        });
        this.apiRequestsService.setAuthorizationHeader(token);
        this.currentUser()
          .then((data: any) => {
            this.user_id = data;
          })
        callback_success();
      }).catch((error) => {
        console.error(error);
        callback_error();
      });
  }


  /**
   * Sign-in a Firebase user with an email address and password.
   * This function also configures the http api-requests service with the token.
   * @param {string} email Email address of the user
   * @param {string} password Password of the user
   * @returns {Promise} A promise that returns true on success and false on failure.
   */
  public loginEmailPassword(email: string, password: string) {
    const auth = this.getAuth();
    return new Promise<boolean>((resolve, reject) => {signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        let callback_success = () => {
          // also check our database
          this.currentUser()
            .then((user) => {
              resolve(true);
            }, err => {
              if (err == "user not in Plantfellow") {
                reject('user not in Plantfellow')
              }
              reject(false);
            })

        };
        let callback_error = () => {
          reject(false);
        }
        this.setHttpHeaderToken(callback_success, callback_error);
      })
      .catch((error) => {
        console.error(error);
        reject(false);
      });
    })
  }

  /**
   * Fetch the current user from the api
   * @returns {Promise<User>} resolved as the current user object on success, otherwise
   *   rejected as an undefined object.
   */
  public currentUser(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.apiRequestsService.sendGetRequest('/api/current_user')
        .subscribe(
          (user: any) => {
            resolve(user)
          },
          err => {
            this.router.navigate(['/login'])
            reject(undefined)
          }
        );
    });
  }

  /**
   * Load currently logged in user object into this.current_user
   * @returns {Promise<boolean>} resolved as true if current user is loaded,
   *    otherwise false
   */
  public getCurrentUser(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.current_user) {
        this.currentUser()
        .then(
          (user: User) => {
            this.current_user = user;
            resolve(true)
          },
          err => {
            this.router.navigate(['/login'])
            reject(false)
          }
        );
      } else {
        resolve(true);
      }
    });
  }


  /**
   * Redirect the browser to the google login page and return to
   * the current page after login is complete.
   */
  public loginGoogleRedirect() {
    const auth = this.getAuth();
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  }


  /**
   * Redirect the browser to the microsoft login page and return
   * to the current page after login is complete.
   */
  public loginMicrosoftRedirect() {
    const auth = this.getAuth();
    const provider = new OAuthProvider('microsoft.com');
    signInWithRedirect(auth, provider);
  }


  /**
   * Obtain login token from sign-in result of a Firebase OAuthProvider.
   * This function also configures the http api-requests service with the token.
   * @returns {Promise} A promise that is either resolved with a message if sign-in was successfull
   *  or null if there was no redirect result. Or rejects with an error message if the login failed.
   */
  getRedirectResult() {
    this.loading = true;
    const auth = this.getAuth();
    return new Promise<any>((resolve, reject) => {
      getRedirectResult(auth)
        .then((result) => {
          if (result) {
            console.debug('User is signed in!');

            let callback_error = () => {
              reject(false);
              this.loading = false;
            }
            if (result.providerId == 'google.com') {
              console.debug("provider: google.com");
              // const credential = GoogleAuthProvider.credentialFromResult(result);
              // const token = credential.accessToken;
              // const user = result.user;
              let callback_success = () => {
                resolve(result);
                this.loading = false;
              };
              this.setHttpHeaderToken(callback_success, callback_error);
            } else if (result.providerId == 'microsoft.com') {
              console.debug("provider: microsoft.com");
              // const credential = OAuthProvider.credentialFromResult(result);
              // const accessToken = credential.accessToken;
              // const idToken = credential.idToken;
              let callback_success = () => {
                resolve('Logged in with Microsoft account');
                this.loading = false;
              };
              this.setHttpHeaderToken(callback_success, callback_error);
            } else if (result.providerId == 'firebase') {
              console.debug("provider: firebase");
              let callback_success = () => {
                resolve('Logged in with Firebase account');
                this.loading = false;
              };
              this.setHttpHeaderToken(callback_success, callback_error);
            } else {
              console.debug('Did not recognize the authentication provider!');
              reject('Did not recognize the authentication provider!');
              this.loading = false;
            }
            return;
          }
          resolve(result);
          this.loading = false;
        })
        .catch((error) => {
          console.debug('Failed to obtain token from OAuth redirect result.');
          console.error(error);
          reject(error);
          this.loading = false;
        });
    });
  }

  /**
   * Sign out the current user
   * This function also resets the http api-requests service header.
   */
  logout() {
    const auth = this.getAuth();
    if (auth.currentUser) {
      signOut(auth).then(() => {
        console.debug('Sign-out was successful!');

        // clear token in http api-requests service
        this.apiRequestsService.initializeHttpHeader();

      }).catch((error) => {
        console.error(error);
      });
    } else {
      console.debug('Not yet logged in!');
    }
  }

  /**
   * Send a password reset email to the user.
   * @param {string} email email address of the user.
   * @param {any} callback_success Callback function on successfully sending reset email
   * @param {any} callback_error Callback function on error when sending reset email
   */
  sendPasswordResetEmail(email: string, callback_success: any, callback_error: any) {
    const auth = this.getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.debug('Password reset email is sent!');
        callback_success();
      })
      .catch((error) => {
        console.error(error);
        callback_error(error);
      });
  }

  /**
   * Fetch user profile data of Identity Provider.
   * @returns {Promise<object>} A promise that either is rejected with an undefined object
   *  or resolved with a user profile object. This object contains the following properties:
   *  - displayName : Display name of the user (default: null)
   *  - email       : Email of the user
   *  - phoneNumber : Phone number of the user (default: null)
   *  - photoURL    : URL to the profile photo of the user (default: null)
   *  - providerId  : Identity provider ID (eg. 'google.com' or 'password' or 'microsoft.com')
   *  - uid         : User ID
   */
  public getUserProfile() {
    const auth = this.getAuth();
    return new Promise<object>((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.debug("User is logged in!");
          resolve(user.providerData[0]);
        } else {
          console.debug("No user logged in!");
          reject(undefined)
        }
      })
    });
  }

  /**
   * Create a new Firebase user.
   * @param {string} email Email address of the new user.
   * @param {string} password The password of the new user.
   * @returns {Promise<any>} A promise that is resolved true when account was successfully created
   *  on error the promise is rejected with the error object.
   */
  createUserWithEmailAndPassword(email: string, password: string) {
    const auth = this.getAuth();
    return new Promise<any>((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          console.debug("Created new account!");
          console.debug(userCredential);
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
    });
  }
}
