import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      this.authService.getAuth().onAuthStateChanged((user) => {
        if (user) {
          let callback_success = () => {
            resolve(true);
          };
          let callback_error = () => {
            this.router.navigate(['/login']);
            reject(false);
          }
          this.authService.setHttpHeaderToken(callback_success, callback_error)
        } else {
          this.router.navigate(['/login']);
          reject(false);
        }
      });
    })
  }
}
